import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as expensesPaths from './paths';
import * as expensesSrcs from './iframe-srcs';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/expenses';
export const menuKeyCode = 'dop-business-expenses';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.appsManagement.expenses"
      />
    ),
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.expenses.budget"
          />
        ),
        menuPath: expensesPaths.BUDGET_PATH,
        sortOrder: 0,
        iframeSrc: expensesSrcs.BUDGET_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.expenses.employeeInfoPermit"
          />
        ),
        menuPath: expensesPaths.EMPLOYEE_INFO_PERMIT_PATH,
        sortOrder: 1,
        iframeSrc: expensesSrcs.EMPLOYEE_INFO_PERMIT_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.expenses.fuelCost"
          />
        ),
        menuPath: expensesPaths.FUEL_COST_PATH,
        sortOrder: 2,
        iframeSrc: expensesSrcs.FUEL_COST_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.expenses.dataExcelTransform"
          />
        ),
        menuPath: expensesPaths.DATA_EXCEL_TRANSFORM_PATH,
        sortOrder: 3,
        iframeSrc: expensesSrcs.DATA_EXCEL_TRANSFORM_SRC,
      },
    ],
  },
];
