'use client';

import * as React from 'react';

export interface IContextValues {
  appCode?: string;
  simpleCode: string;
  simpleCodeCheck: string;
  newSimpleCode: string;
  newSimpleCodeCheck: string;
  errorMessage: string;
}

export interface IContextActions {
  setAppCode?: React.Dispatch<React.SetStateAction<string>>;
  setSimpleCode: React.Dispatch<React.SetStateAction<string>>;
  setSimpleCodeCheck: React.Dispatch<React.SetStateAction<string>>;
  setNewSimpleCode: React.Dispatch<React.SetStateAction<string>>;
  setNewSimpleCodeCheck: React.Dispatch<React.SetStateAction<string>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  appCode: '',
  simpleCode: '',
  simpleCodeCheck: '',
  newSimpleCode: '',
  newSimpleCodeCheck: '',
  errorMessage: '',
});

export const ActionsContext = React.createContext<IContextActions>({
  setAppCode: () => undefined,
  setSimpleCode: () => undefined,
  setSimpleCodeCheck: () => undefined,
  setNewSimpleCode: () => undefined,
  setNewSimpleCodeCheck: () => undefined,
  setErrorMessage: () => undefined,
});

export interface Props {
  appCode?: string;
}

export function Provider({
  appCode: defaultAppCode,
  children,
}: React.PropsWithChildren<Props>) {
  const [appCode, setAppCode] = React.useState(defaultAppCode ?? '');
  const [simpleCode, setSimpleCode] = React.useState('');
  const [simpleCodeCheck, setSimpleCodeCheck] = React.useState('');
  const [newSimpleCode, setNewSimpleCode] = React.useState('');
  const [newSimpleCodeCheck, setNewSimpleCodeCheck] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    setSimpleCode('');
    setSimpleCodeCheck('');
    setNewSimpleCode('');
    setNewSimpleCodeCheck('');
    setErrorMessage('');
  }, [defaultAppCode]);

  const valuesContext: IContextValues = {
    appCode,
    simpleCode,
    simpleCodeCheck,
    newSimpleCode,
    newSimpleCodeCheck,
    errorMessage,
  };

  const actionsContext: IContextActions = {
    setAppCode,
    setSimpleCode,
    setSimpleCodeCheck,
    setNewSimpleCode,
    setNewSimpleCodeCheck,
    setErrorMessage,
  };

  return (
    <ValuesContext value={valuesContext}>
      <ActionsContext value={actionsContext}>{children}</ActionsContext>
    </ValuesContext>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
