import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  SERVICE_APP_BUSINESS_PATH,
  SERVICE_APP_EMPLOYEE_PATH,
} from '../../../../../menus/basicManagement/paths';
import { getInUseAppsInfo, QUERY_KEY } from '../../apis/in-use-apps';
import { AppsPerPortal } from './apps-per-portal';

export function InUseAppsInfo() {
  const { data: inUseAppsInfo, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: getInUseAppsInfo,
  });

  const { t } = useTranslation('component');

  if (error) {
    console.error(
      'GlobalConfig > BasicManagement > ServiceInfo > InUseAppsInfo Error:',
      error,
    );
  }

  return (
    <div className="p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <p className="py-[8px] text-[#333333] text-[22px] font-[600]">
        {t('globalconfig.basicManagement.serviceInfo.inUseAppsInfo.title')}
      </p>
      <AppsPerPortal
        portalName={t('service.employeePortal.name')}
        appManagementDirectLink={SERVICE_APP_EMPLOYEE_PATH}
        basicAppInfoList={
          inUseAppsInfo?.employee.defaultCompanyAppUsageList || []
        }
        purchasedAppInfoList={
          inUseAppsInfo?.employee.purchasedCompanyAppUsageList || []
        }
        className="mt-[24px]"
      />
      <AppsPerPortal
        portalName={t('service.businessPortal.name')}
        appManagementDirectLink={SERVICE_APP_BUSINESS_PATH}
        basicAppInfoList={
          inUseAppsInfo?.business.defaultCompanyAppUsageList || []
        }
        purchasedAppInfoList={
          inUseAppsInfo?.business.purchasedCompanyAppUsageList || []
        }
        className="mt-[64px]"
      />
    </div>
  );
}
