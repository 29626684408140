const srcPrefix = '/eacc/web/#payroll';

export const MASKING_SRC = srcPrefix + '/masking';
export const WORKING_TIME_SRC = srcPrefix + '/working-time';
export const PAYROLL_SRC = srcPrefix + '/payroll';
export const PAYSLIP_SRC = srcPrefix + '/payslip';
export const PAYMENT_SRC = srcPrefix + '/payment';
export const DEDUCTION_SRC = srcPrefix + '/deduction';
export const INSURANCE_SRC = srcPrefix + '/insurance';
export const TAX_SRC = srcPrefix + '/tax';
export const ALLOWANCE_SRC = srcPrefix + '/allowance';
