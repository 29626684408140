import dynamic from 'next/dynamic'
// 서비스 정보는 첫 페이지이기 때문에 동기 방식으로 로딩
export { ServiceInfo as ServiceInfoPage } from './service-info';

export const BusinessPortalAppManagementPage = dynamic(() => import('./business-portal-app-management'));
export const EmpPortalAppManagementPage = dynamic(() => import('./emp-portal-app-management'));
export const LogoThemeManagementPage = dynamic(() => import('./logo-theme-management'));
export const ProfileManagementPage = dynamic(() => import('./profile-management'));
export const PopupAnnouncementPage = dynamic(() => import('./popup-announcement'));
export const ServiceMapPage = dynamic(() => import('./servicemap'));
export const CompanyScheduleManagementPage = dynamic(() => import('./company-schedule-management'));
