import { API_CHECK_PASSWORD_URL } from './constants';

export interface Response {
  code?: string;
  name?: string;
  message?: string;
}

export async function checkPasswordSync(currentPassword: string) {
  const res = await fetch(API_CHECK_PASSWORD_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ currentPassword }),
  });

  if (!res.ok) {
    throw new Error(((await res.json()) as Response).message);
  }
}
