import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as payrollPaths from './paths';
import * as payrollSrcs from './iframe-srcs';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/payroll';
export const menuKeyCode = 'dop-business-payroll';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.appsManagement.payroll"
      />
    ),
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.payroll.masking"
          />
        ),
        menuPath: payrollPaths.MASKING_PATH,
        sortOrder: 7,
        iframeSrc: payrollSrcs.MASKING_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.payroll.workingTime"
          />
        ),
        menuPath: payrollPaths.WORKING_TIME_PATH,
        sortOrder: 8,
        iframeSrc: payrollSrcs.WORKING_TIME_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.payroll.payroll"
          />
        ),
        menuPath: payrollPaths.PAYROLL_PATH,
        sortOrder: 0,
        iframeSrc: payrollSrcs.PAYROLL_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.payroll.payslip"
          />
        ),
        menuPath: payrollPaths.PAYSLIP_PATH,
        sortOrder: 1,
        iframeSrc: payrollSrcs.PAYSLIP_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.payroll.payment"
          />
        ),
        menuPath: payrollPaths.PAYMENT_PATH,
        sortOrder: 2,
        iframeSrc: payrollSrcs.PAYMENT_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.payroll.deduction"
          />
        ),
        menuPath: payrollPaths.DEDUCTION_PATH,
        sortOrder: 3,
        iframeSrc: payrollSrcs.DEDUCTION_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.payroll.insurance"
          />
        ),
        menuPath: payrollPaths.INSURANCE_PATH,
        sortOrder: 4,
        iframeSrc: payrollSrcs.INSURANCE_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.payroll.tax"
          />
        ),
        menuPath: payrollPaths.TAX_PATH,
        sortOrder: 5,
        iframeSrc: payrollSrcs.TAX_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.payroll.allowance"
          />
        ),
        menuPath: payrollPaths.ALLOWANCE_PATH,
        sortOrder: 6,
        iframeSrc: payrollSrcs.ALLOWANCE_SRC,
      },
    ],
  },
];
