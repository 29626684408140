import * as React from 'react';

export interface IContextValues {
  readonly cardOpenState: boolean;
}

export interface IContextActions {
  setCardOpenState: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  cardOpenState: false,
});

export const ActionsContext = React.createContext<IContextActions>({
  setCardOpenState: () => undefined,
});

export interface Props {}

export function Provider({ children }: React.PropsWithChildren<Props>) {
  const [cardOpenState, setCardOpenState] = React.useState(false);

  const contextValues = { cardOpenState };
  const contextActions = { setCardOpenState };

  return (
    <ActionsContext value={contextActions}>
      <ValuesContext value={contextValues}>{children}</ValuesContext>
    </ActionsContext>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
