'use client';

import Button from './Button';
import Layer from './Layer';

interface Props {
  id: string;
  children: React.ReactNode;
  type: string; // button, layer
}

const Backdrop = ({ children, type, id }: Props) => {
  return type === 'button' ? (
    <Button id={id}>{children}</Button>
  ) : (
    <Layer id={id}>{children}</Layer>
  );
};
export default Backdrop;
