import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as hrPaths from './paths';
import * as hrSrcs from './iframe-srcs';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/mp-hr';
export const menuKeyCode = 'dop-business-hr';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.appsManagement.mpHr"
      />
    ),
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mpHr.empInfo"
          />
        ),
        menuPath: hrPaths.EMP_INFO_PATH,
        sortOrder: 0,
        iframeSrc: hrSrcs.EMP_INFO_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mpHr.masking"
          />
        ),
        menuPath: hrPaths.MASKING_PATH,
        sortOrder: 1,
        iframeSrc: hrSrcs.MASKING_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mpHr.certStamp"
          />
        ),
        menuPath: hrPaths.CERTIFICATE_STAMP_PATH,
        sortOrder: 2,
        iframeSrc: hrSrcs.CERTIFICATE_STAMP_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mpHr.empInfoPermit"
          />
        ),
        menuPath: hrPaths.EMP_INFO_PERMIT_PATH,
        sortOrder: 3,
        iframeSrc: hrSrcs.EMP_INFO_PERMIT_SRC,
      },
    ],
  },
];
