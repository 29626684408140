import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as companyFolderPaths from './paths';
import * as appManagementPages from '../../../pages/app-management';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/company-folder';
export const menuKeyCode = 'dop-employee-appr-company-folder';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.appsManagement.companyFolder"
      />
    ),
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.companyFolder.manager"
          />
        ),
        menuPath: companyFolderPaths.MANAGER_PATH,
        sortOrder: 0,
        page: <appManagementPages.CompanyFolderManagerPage />,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.companyFolder.management"
          />
        ),
        menuPath: companyFolderPaths.MANAGEMENT_PATH,
        sortOrder: 1,
        page: <appManagementPages.CompanyFolderManagementPage />,
      },
    ],
  },
];
