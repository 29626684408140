import dynamic from 'next/dynamic';

export const BackUpManagementPage = dynamic(() => import('./BackUpManagement'));
export const CurrentDataUsagePage = dynamic(() => import('./CurrentDataUsage'));
export const DataRequestManagementPage = dynamic(() => import('./DataRequestManagement'));
export const DeletedDeptManagementPage = dynamic(() => import('./DeletedDeptManagement'));
export const DeptManagementPage = dynamic(() => import('./DeptManagement'));
export const MemberManagementPage = dynamic(() => import('./MemberManagement'));
export const SetUsageNotificationPage = dynamic(() => import('./SetUsageNotification'));
export const UploadDataManagementPage = dynamic(() => import('./UploadDataManagement'));
export const UploadPolicyManagementPage = dynamic(() => import('./UploadPolicyManagement'));
