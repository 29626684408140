'use client';

import * as Jotai from 'jotai';
import type { PropsWithChildren } from 'react';
import { screenLockStore } from '../model/store';
import { ScreenLockTypes } from '../types';
import { AppScreenLock } from './app-screen-lock';
import { ExtScreenLock } from './ext-screen-lock';

export interface Props {
  lockType?: ScreenLockTypes;
  title?: string;
}

export function ScreenLockProvider({
  children,
  lockType = 'app',
  title,
}: PropsWithChildren<Props>) {
  return (
    <Jotai.Provider store={screenLockStore}>
      {lockType === 'app' ? (
        <AppScreenLock>{children}</AppScreenLock>
      ) : (
        <ExtScreenLock title={title}>{children}</ExtScreenLock>
      )}
    </Jotai.Provider>
  );
}

ScreenLockProvider.displayName = 'ScreenLockProvider';

export default ScreenLockProvider;
