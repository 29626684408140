'use client';

import dynamic from 'next/dynamic';
import Backdrop from '../../Backdrop';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { OrgChartIcon } from '@dop-ui/icons/react/dop/24/OrgChart';
import { FuncButton } from '../FuncButton';
import { generateUUID } from '@daouoffice/next/lib/utils/util';

const OrgChart = dynamic(() => import('@daouoffice/next/components/OrgChart'));

export function OrgChartButton() {
  const { t } = useTranslation();
  const uuid = generateUUID();

  return (
    <>
      <Backdrop type={'button'} id={uuid}>
        <FuncButton icon={OrgChartIcon} label={t('조직도')} />
      </Backdrop>
      <Backdrop type={'layer'} id={uuid}>
        <OrgChart
          useHeader={false}
          useFooter={false}
          useMultiSelect={false}
          useResize={true}
          style={{
            top: '160px',
            left: '81px',
            bottom: '16px',
            borderRadius: '16px',
            width: '360px',
            minWidth: '360px',
            maxWidth: '720px',
            resize: 'horizontal',
          }}
        ></OrgChart>
      </Backdrop>
    </>
  );
}

export default OrgChartButton;
