import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { IN_USE_APPS_INFO_API } from '../../constants';
import { AppInfo } from '../types';
import { BaseResponse } from '../../types';

interface InUseAppsResponse {
  defaultCompanyAppUsageList: AppInfo[];
  purchasedCompanyAppUsageList: AppInfo[];
}

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.ServiceInfo.InUseAppsInfo';

export async function getInUseAppsInfo() {
  const empResponse = await fetch(IN_USE_APPS_INFO_API('employee'), {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const mpResponse = await fetch(IN_USE_APPS_INFO_API('business'), {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const empResponseData =
    (await empResponse.json()) as BaseResponse<InUseAppsResponse>;
  const mpResponseData =
    (await mpResponse.json()) as BaseResponse<InUseAppsResponse>;

  return {
    employee: empResponseData.data,
    business: mpResponseData.data,
  };
}
