'use client';

import { useEffect, useState } from 'react';

interface Props {
  id: string;
  children: React.ReactNode;
}

const backdropMap = {};

const Layer = ({ children, id }: Props) => {
  // const [left, setLeft] = useState(0);
  // const [top, setTop] = useState(0);
  const [display, setDisplay] = useState('none');
  let isDragging = false;

  const initLayer = () => {
    if (backdropMap[id]) return;
    backdropMap[id] = true;
    console.log('initLayer');
    // document.addEventListener('click', handleClick);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('backdrop', handleBackdrop);
  };

  // TODO. context menu 용도
  const setPosition = (button: Element) => {
    if (!button) return;
    // const react: DOMRect = button.getBoundingClientRect();
    // console.log(`${react.left} / ${react.top + react.height}`);
    // setLeft(react.left);
    // setTop(react.top + react.height);
  };

  const handleMouseDown = () => {
    isDragging = false;
  };
  const handleMouseMove = () => {
    isDragging = true;
  };
  const handleMouseUp = (e: MouseEvent) => {
    if (!isDragging) handleClick(e);
    isDragging = false;
  };

  const handleClick = (e: MouseEvent) => {
    const target: HTMLElement = e.target as HTMLElement;
    const isBackdropLayer = target.hasAttribute('data-backdrop-layer')
      ? true
      : !!target.closest(`[data-backdrop-layer="${id}"]`);

    if (isBackdropLayer) return;

    const button = target.hasAttribute('data-backdrop-button')
      ? target
      : target.closest(`[data-backdrop-button="${id}"]`);

    const buttonId = button?.getAttribute('data-backdrop-button');
    setDisplay(buttonId === id ? '' : 'none');
    setPosition(button);
  };

  const handleBackdrop = (e: CustomEvent) => {
    const detail: MouseEvent = e.detail as MouseEvent;
    const button: Element = detail.currentTarget as Element;

    setPosition(button);
  };

  useEffect(() => initLayer());

  return (
    <div data-backdrop-layer={id} style={{ display }}>
      {children}
    </div>
  );
};

export default Layer;
