'use client';

interface Props {
  id: string;
  children: React.ReactNode;
}

const Button = ({ children, id }: Props) => {
  const handleClick = (e: React.MouseEvent) => {
    const event = new CustomEvent('backdrop', {
      detail: { currentTarget: e.currentTarget },
    });
    document.dispatchEvent(event);
  };

  return (
    <div data-backdrop-button={id} onClick={handleClick} aria-hidden="true">
      {children}
    </div>
  );
};

export default Button;
