import { APPS_MANAGEMENT } from '../../main/menuPaths';

const menuKeyPath = APPS_MANAGEMENT + '/payroll';

export const MASKING_PATH = menuKeyPath + '/masking';
export const WORKING_TIME_PATH = menuKeyPath + '/working-time';
export const PAYROLL_PATH = menuKeyPath + '/payroll';
export const PAYSLIP_PATH = menuKeyPath + '/payslip';
export const PAYMENT_PATH = menuKeyPath + '/payment';
export const DEDUCTION_PATH = menuKeyPath + '/deduction';
export const INSURANCE_PATH = menuKeyPath + '/insurance';
export const TAX_PATH = menuKeyPath + '/tax';
export const ALLOWANCE_PATH = menuKeyPath + '/allowance';
