import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as reportPaths from './paths';
import * as appManagementPages from '../../../pages/app-management';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/report';
export const menuKeyCode = 'dop-employee-report';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.appsManagement.report"
      />
    ),
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.report.form"
          />
        ),
        menuPath: reportPaths.FORM_PATH,
        sortOrder: 0,
        page: <appManagementPages.ReportFormPage />,
      },
    ],
  },
];
