import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as worksPaths from './paths';
import * as appManagementPages from '../../../pages/app-management';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/works';
export const menuKeyCode = 'dop-employee-works';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: <>Works</>,
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.works.managerSetting"
          />
        ),
        menuPath: worksPaths.MANAGER_PATH,
        sortOrder: 0,
        page: <appManagementPages.WorksManagerPage />,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.works.appList"
          />
        ),
        menuPath: worksPaths.APP_LIST_PATH,
        sortOrder: 1,
        page: <appManagementPages.WorksAppListPage />,
      },
    ],
  },
];
