import dynamic from 'next/dynamic';

export const AdminLogConfigPage = dynamic(() => import('./admin-log-config'));
export const AdminManagementPage = dynamic(() => import('./AdminManagement'));
export const AppManagementPage = dynamic(() => import('./AppManagement'));
export const LoginSecureConfigPage = dynamic(() => import('./LoginSecureConfig'));
export const MediaSecureConfigPage = dynamic(() => import('./MediaSecureConfig'));
export const MemberLogConfigPage = dynamic(() => import('./MemberLogConfig'));
export const MobileBlockConfigPage = dynamic(() => import('./MobileBlockConfig'));
export const MobileDeviceAccessConfigPage = dynamic(() => import('./MobileDeviceAccessConfig'));
export const MobileSecureConfigPage = dynamic(() => import('./MobileSecureConfig'));
export const PasswordPolicyConfigPage = dynamic(() => import('./password-policy-config'));
export const TwoFactorAuthConfigPage = dynamic(() => import('./TwoFactorAuthConfig'));
export const TfaExceptionConfigPage = dynamic(() => import('./tfa-exception-config'));
export const TfaErrorLogPage = dynamic(() => import('./TfaErrorLog'));
export const TfaRegistrationStatusPage = dynamic(() => import('./tfa-registration-status'));
