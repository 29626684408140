import { fetchGraphQL } from '@dop-ui/react/shared/lib/fetch/client';
import { gql } from 'graphql-request';
import { AuthorizedAppCode } from '../../../types';

const appCodesQuery = gql`
  query MyQuery {
    me {
      accessibleAppCodeList {
        code
        portalType
        isManager
      }
    }
  }
`;

interface AppCodeResponse {
  data: {
    me: {
      accessibleAppCodeList: AppCode[];
    };
  };
}

interface AppCode {
  code: string;
  portalType: string;
  isManager: boolean;
}

export const QUERY_KEY = 'GlobalConfig.Menu';

export async function getAppCodes() {
  const response = await fetchGraphQL({ query: appCodesQuery });

  const responseData = (await response.json()) as AppCodeResponse;

  const authorizedAppCodes = responseData.data.me.accessibleAppCodeList
    .filter((app) => app.isManager)
    .map(adaptor);

  return {
    setting: authorizedAppCodes.filter(
      (codeInfo) => codeInfo.type === 'SETTING',
    ),
    employee: authorizedAppCodes.filter(
      (codeInfo) => codeInfo.type === 'EMPLOYEE',
    ),
    business: authorizedAppCodes.filter(
      (codeInfo) => codeInfo.type === 'BUSINESS',
    ),
    accessibles: responseData.data.me.accessibleAppCodeList.map(
      (info) => info.code,
    ),
  };
}

function adaptor(appCode: AppCode): AuthorizedAppCode {
  return {
    appCode: appCode.code,
    type: appCode.portalType,
  };
}
