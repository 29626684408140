import dynamic from 'next/dynamic';

export const ReportFormPage = dynamic(() => import('./report-form'));
export const SurveyStatusPage = dynamic(() => import('./survey/survey-status'));
export const SurveyManagerSettingPage = dynamic(
  () => import('./survey/survey-manager'),
);
export const CommunitySetupManagementPage = dynamic(
  () => import('./community/community-setup-management'),
);
export const CommunityAllStatusPage = dynamic(
  () => import('./community/community-all-status'),
);
export const CommunityBoardStatusPage = dynamic(
  () => import('./community/community-board-status'),
);
export const CommunityBoardStatusByCommunityPage = dynamic(
  () => import('./community/community-board-status-by-community'),
);
export const WorksManagerPage = dynamic(() => import('./works/manager'));
export const WorksAppListPage = dynamic(() => import('./works/app-list'));
export const PcMessengerSetting = dynamic(() => import('./messenger/pc'));
export const MobileMessengerSetting = dynamic(
  () => import('./messenger/mobile'),
);
export const AllBoardStatusPage = dynamic(
  () => import('./board/all-board-status'),
);
export const CompanyFolderManagerPage = dynamic(
  () => import('./company-folder/manager'),
);
export const CompanyFolderManagementPage = dynamic(
  () => import('./company-folder/management'),
);

export const ContactSettingPage = dynamic(
  () => import('./contact/contact-setting'),
);
export const EntireContactPage = dynamic(
  () => import('./contact/entire-contact'),
);
export const DepartmentBoardStatusPage = dynamic(
  () => import('./board/department-board-status'),
);
