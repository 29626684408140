import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as financePaths from './paths';
import * as financeSrcs from './iframe-srcs';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/finance';
export const menuKeyCode = 'dop-business-finance';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.appsManagement.finance"
      />
    ),
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.finance.discountFee"
          />
        ),
        menuPath: financePaths.DISCOUNT_FEE_PATH,
        sortOrder: 0,
        iframeSrc: financeSrcs.DISCOUNT_FEE_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.finance.accountMemo"
          />
        ),
        menuPath: financePaths.ACCOUNT_MEMO_PATH,
        sortOrder: 1,
        iframeSrc: financeSrcs.ACCOUNT_MEMO_SRC,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.finance.exchange"
          />
        ),
        menuPath: financePaths.EXCHANGE_PATH,
        sortOrder: 2,
        iframeSrc: financeSrcs.EXCHANGE_SRC,
      },
    ],
  },
];
